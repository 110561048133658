<template>
  <div class="gradient">
    <div class="abs_nav home_overlay">
      <div class="container-lg">
        <div class="row m-0 align-items-center nav-holder">
          <div class="col-12 pt-4 px-0">
            <div class="row m-0 align-items-center">
              <div class="col-3 ps-0">
                <img :src="logo" class="img-fluid cursor logoZa" @click="goTo">
              </div>
              <div class="col">
                <div class="row m-0 justify-content-center">
                  <TheSteps :activeComponent="activeComponent" :wizardSteps="wizardSteps" />
                </div>
              </div>
              <div class="col-3 pe-0">
                <TopMenuDonateButton />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="back" >
    <div class="bg_overlay"></div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    TopMenuDonateButton: defineAsyncComponent(() => import('@/views//nav/components/TopMenuDonateButton.vue')),
    TheSteps: defineAsyncComponent(() => import('./TheSteps.vue'))
  },
  name: 'DesktopWizardTopBar',
  props: ['activeComponent', 'wizardSteps'],
  data () {
    return {
      logo: process.env.VUE_APP_LOGO,
      url: process.env.VUE_APP_URL_BASE
    }
  },
  methods: {
    goTo () {
      window.scroll(0, 0)
      this.$router.push('/')
    }
  }
}

</script>

<style scoped>
.back {
  background-color: transparent;
  background-image: linear-gradient(180deg, #0D351C 0%, #1A6836 70%);
  height: 335px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  z-index: 0;
  position: relative;
}
.bg_overlay {
  background-image: url(../../../../../../../assets/images/p.png);
  background-size: 17% auto;
  opacity: 0.05;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

@media (min-width: 992px) {

  .gradient {
    position: relative;
    background-color: transparent;
    background-color: #03191300;
  }
  .abs_nav  {
    position: absolute;
    top: 0;
    z-index: 99;
    width: 100%;
  }
  .home_overlay {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.88) 0%, rgba(0, 0, 0, 0) 91%);
  }
  .logoZa  {
    max-height: 75px;
  }
  .nav-holder {
    min-height: 133px;
  }
}

</style>
